<template>
  <div id="map_container" style="width: 100%; height: 100%;" />
</template>
<script>
export default {
  name: 'baiduMap',
  props: {
    dataList: {
      type: Array,
      default: () => {
      }
    }
  },
  data() {
    return {
      map: null,
      list: [
        {
          value: [120.589448, 30.057908],
          name: '刘大龙',
          time: '2022.12.22  12:37:21',
          select: false
        }, {
          value: [120.590023, 29.996872],
          name: '张龙',
          time: '2099.07.22  12:37:21',
          select: false
        }
      ]
    }
  },
  mounted() {
    window.closeInfo = this.closeInfo
    this.init()
  },
  methods: {
    /* 初始化 */
    init() {
      this.$nextTick(() => {
        this.map = new BMapGL.Map('map_container', {
          minZoom: 11,
          maxZoom: 18
        }) // 创建Map实例
        const point = {
          lng: 120.585888,
          lat: 30.020511
        }
        // 初始化地图,设置中心点坐标和地图级别
        this.map.centerAndZoom(new BMapGL.Point(point.lng, point.lat), 12)
        // 开启鼠标滚轮缩放
        this.map.enableScrollWheelZoom(true)
        // 禁用双击放大
        this.map.disableDoubleClickZoom()
        // 设置地图仰角
        // this.map.setTilt(70)
        // 设置地图样式
        // this.map.setMapStyleV2({
        //   styleJson: require('@/assets/json/baidu_style/baidu_map_config.json')
        // })
        if (this.list) {
          this.addNode()
        }
      })
    },
    /* 加点 */
    addNode() {
      const that = this
      this.list.forEach(item => {
        /* 打点 */
        const point = new BMapGL.Point(item.value[0], item.value[1])
        this.map.setCenter(point) // 设置中心
        const mk = new BMapGL.Marker(point, {
          icon: new BMapGL.Icon(require('@/assets/img/mapCamera0.png'), new BMapGL.Size(32, 36)),
          title: item.name
        })
        this.map.addOverlay(mk)
        /* 打点end */
        mk.addEventListener('click', (e) => {
          that.openInfo(point, item)
          that.map.setCenter(point)
          setTimeout(() => {
            that.newMk = new BMapGL.Marker(point, {
              icon: new BMapGL.Icon(require('@/assets/img/mapCamera2.png'), new BMapGL.Size(32, 36)),
              title: item.name
            })
            that.map.removeOverlay(that.newMk)
            that.map.removeOverlay(e.currentTarget)
            that.select = e.currentTarget
            that.map.addOverlay(that.newMk)
          }, 0)
        })
      })
    },
    /* 点击弹窗 */
    openInfo(point, item) {
      const that = this
      const viewWin =
        `<div class="p20 text-white">
          <p>${item.name}<span onclick="closeInfo()" class="pointer p5 bg-primary ml8 br5 text-white">关闭</span></p>
          <p>${item.time}</p>
        </div>`
      const infoWindow = new BMapGL.InfoWindow(viewWin, { offset: new BMapGL.Size(-20, -45) })
      infoWindow.addEventListener('close', function() {
        that.map.removeOverlay(that.newMk)
        that.map.addOverlay(that.select)
      })
      this.map.openInfoWindow(infoWindow, point)
    },
    /* 关闭弹窗 */
    closeInfo() {
      this.map.closeInfoWindow()
    },
    /* 设置中心点 */
    setMapCenter(row) {
      const point = new BMapGL.Point(row.lng, row.lat)
      this.map.setCenter(point)
    },
  }
}
</script>
<style lang="scss" scoped>
  /* 百度弹窗样式 */
  #map_container ::v-deep .BMap_bubble_pop {
    background: url('~@/assets/img/mapBG.png') no-repeat; // 弹窗背景
    background-size: 100% 100%;
    border: none !important;
    background-color: rgba(0,0,0,0) !important;
    width: 400px !important;
    .BMap_bubble_center {
      width: 100% !important;
      height: auto;
      .BMap_bubble_content {
        width: 100% !important;
      }
    }
    /* 关闭按钮 */
    .BMap_bubble_top {
      display: none;
    }
    /* 底下图标 */
    >img {
      content: url('~@/assets/img/mapIcon.png');
      top: 135px !important;
      width: 50px !important;
      height: 60px !important;
      /*left: 214px !important;*/
    }
  }
</style>